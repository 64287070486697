.dashboard-title {
  font-weight: 700;
  font-size: 20px;
}

.dashboard-statistics-box {
}

.dashboard-statistics-user-box {
  padding: 5px;
}

.dashboard-statistics-user-box-container {
  border: 2px solid black;
  padding: 5px;
  border-radius: 10px;
}

.dashboard-text {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0px;
  color: #2c5282;
}

.dashboard-download-button {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  font-size: 28px;
  color: #2a4365;
  float: right;
}

.dashboard-download-text {
  color: #2a4365;
  float: right;
  margin-right: 10px;
}
